import React, { useState } from "react";
import { useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";

import * as Yup from "yup";
import ademail from "../../services/ademail";

import { Container } from "./styles";

import Map from "../../components/Map";

export default function Contato() {
  const [btnLoader, setBtnLoader] = useState(false);

  const notify = () =>
    toast.error("Ocorreu uma falha.", {
      style: {
        border: "1px solid #f7312a",
        boxShadow: "10px 10px 75px -13px rgba(247,49,42,1)",
        fontFamily: "Montserrat",
      },
    });
  const notifySuccess = () =>
    toast.success("Enviado com sucesso!", {
      style: {
        border: "1px solid #28fc76",
        boxShadow: "10px 10px 75px -13px rgba(40,252,118,1)",
        fontFamily: "Montserrat",
      },
    });

  const formik = useFormik({
    initialValues: {
      nome: "",
      email: "",
      mensagem: "",
    },

    validationSchema: Yup.object({
      nome: Yup.string().required("*Campo nome é obrigatório"),
      email: Yup.string()
        .email("Informe um e-mail válido")
        .required("*Campo e-mail é obrigatório"),
      mensagem: Yup.string().required("*Campo mensagem é obrigatório"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                                  <p>Nome: ${values.nome}</p>
                                  <p>E-mail: ${values.email}</p>
                                  <p>Mensagem: ${values.mensagem}</p>
                                `;

      const mail = {
        to: "contato@rbs.com.br",
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(["web@agenciaade.com.br"]),
        subject: "Novo contato via site - RBS Odontologia e Estética Facial",
        message: body,
      };

      try {
        // Sucesso ao enviar
        setBtnLoader(true);
        await ademail.post("/email", mail);
        notifySuccess();
        resetForm();
        setBtnLoader(false);
      } catch (error) {
        // Erro ao enviar
        setBtnLoader(false);
        notify();
      }
    },
  });

  return (
    <>
      <Toaster position="bottom-right" />
      <Container className="section-7" id="contato">
        <div className="container">
          <div className="content">
            <div className="contato">
              <div className="top-text">
                <p>Fale</p>
                <p>com a gente!</p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="item">
                  <label htmlFor="">Seu nome*</label>
                  <input
                    id="name"
                    className="nome"
                    type="text"
                    name="nome"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nome}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                </div>
                <div className="item">
                  <label htmlFor="">Seu endereço de email*</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span>{formik.errors.email}</span>
                  ) : null}
                </div>
                <div className="item">
                  <label htmlFor="">Insira sua mensagem*</label>
                  <textarea
                    id="mensagem"
                    name="mensagem"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mensagem}
                  />
                  {formik.touched.mensagem && formik.errors.mensagem ? (
                    <span>{formik.errors.mensagem}</span>
                  ) : null}
                </div>
                {/* <div className="item">
                    <label htmlFor="">Data de nascimento:</label>
                    <br />
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        inputReadOnly
                        className="date-picker"
                        format="DD-MM-YYYY"
                      />
                    </ConfigProvider>
                  </div> */}

                <div className="submit-button">
                  {btnLoader ? (
                    <button disabled className="wait" type="submit">
                      Carregando
                    </button>
                  ) : (
                    <button type="submit">Agendar Consulta</button>
                  )}
                </div>
              </form>
            </div>
            <div className="map">
              <Map />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  .section-1 {
    margin: 0 auto;
    .container-slide-1 {
      position: relative;
      height: 100vh;
      width: 100%;

      .items {
        position: absolute;
        top: 40%;
        left: 17%;
        p {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 4rem;
          position: relative;
          z-index: 2;
          color: #202d2d;

          @media screen and (max-width: 680px) {
            font-size: 2rem;
          }
        }

        p.description {
          font-size: 1.6rem;
          color: #fff;
          width: 30%;
          top: -4rem;
          line-height: 1.4;
          position: relative;

          @media screen and (max-width: 680px) {
            width: 70%;
            margin-top: 3rem;
          }
        }

        p.handwriting {
          font-family: "Angelic";
          color: #fff;
          font-size: 12rem;
          margin-top: -10rem;
          position: relative;
          z-index: 2;
          left: -7%;
          @media screen and (max-width: 680px) {
            margin-top: -4rem;
            font-size: 7rem;
          }
        }

        .green-box {
          height: 35rem;
          width: 20rem;
          position: absolute;
          top: -25%;
          left: -7%;
          z-index: 1;
          background: var(--primary-green);
        }
      }
    }
    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    .BrainhubCarousel__container {
      position: relative;
    }
    .BrainhubCarousel__dots {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .BrainhubCarousel__dot {
      width: 3rem;
      margin-left: 4rem;
      border: 2px solid transparent;
      background: var(--primary-green);
    }

    .BrainhubCarousel__dots
      .BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
      background: none;
      border: 2px solid #fff;
    }

    .BrainhubCarousel__dots .BrainhubCarousel__dot {
      padding: 4px;
      opacity: 1;
    }

    .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
      display: none;
    }
  }

  .section-2 {
    position: relative;
    height: auto;
    background: url(${require("../../assets/Fotos/image2.jpg")});
    background-position: center top;
    background-size: cover;
    margin: 0 auto;
    padding: 28rem 0;

    @media screen and (max-width: 680px) {
      padding: 28rem 0;
    }

    img {
      width: 100vw;
    }

    .content {
      position: relative;
      top: 0;

      .top-text {
        text-align: center;
        position: relative;
        margin-bottom: 13rem;
        ::after {
          position: absolute;
          content: "";
          bottom: -100%;
          left: 50%;
          width: 80%;
          transform: translate(-50%, 50%);
          height: 0.1rem;
          background: #000;
          opacity: 0.1;
        }
        p:first-child {
          font-family: "Montserrat";
          font-weight: 300;
          font-size: 4rem;
          line-height: 1;
          color: var(--dark-color-titles);
        }
        p:last-child {
          font-family: "Angelic";
          font-size: 12rem;
          /* margin-top: -8rem; */
          line-height: 0;
          color: var(--primary-green);
        }
      }

      .bottom-text {
        padding: 0 15%;
        p {
          font-family: "Montserrat";
          font-weight: 500;
          font-size: 1.4rem;
          color: #767676;
          line-height: 1.4;
          margin-top: 2rem;
          text-align: center;
        }

        .counter-numbers-main {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
          margin-top: 5rem;
          .counter-numbers {
            text-align: center;
            @media screen and (max-width: 680px) {
              :not(:last-child) {
                margin-bottom: 5rem;
              }
            }

            p {
              color: #767676;
              margin-top: 0;
              letter-spacing: 2px;
            }
            span {
              font-size: 4rem;
              font-family: "Montserrat";
              font-weight: 900;
              color: var(--primary-green);
            }
          }
        }
      }
    }
  }

  .section-3 {
    position: relative;
    height: 100rem;
    background: url(${require("../../assets/Fotos/image3.jpg")}) center
      no-repeat;
    background-size: cover;
    margin: 0 auto;

    .content {
      .items {
        position: absolute;
        top: 27%;

        left: 17%;

        @media screen and (max-width: 680px) {
          top: 30%;
        }

        .aspas {
          position: absolute;
          left: -10rem;
          top: 10rem;

          @media screen and (max-width: 680px) {
            left: -6rem;
            top: 0;
          }

          img {
            width: 90%;
          }
        }

        p {
          font-family: "Montserrat";
          font-weight: 300;
          font-size: 4rem;
          position: relative;
          z-index: 2;
          color: var(--dark-color-titles);
          width: fit-content;

          @media screen and (max-width: 680px) {
            font-size: 3rem;
          }
        }

        p.unknownAuthor {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 1.4rem;
          text-transform: uppercase;
          letter-spacing: 2px;
          color: #767676;
          margin-top: 1rem;
        }

        p.description {
          font-size: 1.6rem;
          color: #fff;
          width: 40%;
          top: 0;
          position: relative;
          line-height: 1.7;
          @media screen and (max-width: 680px) {
            width: 90%;
          }
        }

        p.handwriting {
          font-family: "Angelic";
          color: #fff;
          font-size: 14rem;
          margin-top: -8rem;
          position: relative;
          z-index: 2;
          left: -10%;

          @media screen and (max-width: 680px) {
            margin-top: -3rem;
            font-size: 7rem;
          }
        }
      }
    }
  }

  .section-4 {
    position: relative;
    height: auto;
    background: url(${require("../../assets/Fotos/image4.jpg")}) center
      no-repeat;
    background-size: cover;
    margin: 0 auto;
    padding: 28rem 0;

    .content {
      position: relative;
      top: 0;
      width: 100%;

      .top-text {
        text-align: center;
        position: relative;
        ::after {
          position: absolute;
          content: "";
          top: 90%;
          transform: translate(0, 12px);
          left: 50%;
          width: 80%;
          transform: translate(-50%, 0);
          height: 0.1rem;
          background: #000;
          opacity: 0.1;
        }
        p:first-child {
          font-family: "Montserrat";
          font-weight: 300;
          font-size: 4rem;
          color: var(--dark-color-titles);
        }
        p:last-child {
          font-family: "Angelic";
          font-size: 12rem;
          margin-top: -8rem;
          color: var(--primary-green);

          @media screen and (max-width: 680px) {
            font-size: 7rem;
            margin-top: -4rem;
          }
        }
      }

      .BrainhubCarousel__container {
        width: 100%;
        margin: 0 auto;
        max-width: 130rem;

        .box-container {
          background: #efefef;
          padding: 8rem 2rem 4rem;
          height: 40rem;
          margin-top: 4rem;
          overflow-y: scroll;
          transition: all ease 450ms;

          :hover {
            background: var(--primary-green);

            .texts {
              h2 {
                color: #fff;
              }
              p {
                color: #fff;
              }
            }

            .icon {
              transition: all ease 250ms;
              background: var(--primary-green);
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }

          .icon {
            background: #efefef;
            border-radius: 100%;
            height: 8rem;
            width: 8rem;
            position: absolute;
            top: 0;
            left: 5%;
            border: 2px solid #e3e3e3;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 4.5rem;
              /* height: 10rem; */
              max-width: 100%;
            }
          }

          .texts {
            h2 {
              font-family: "Montserrat";
              font-size: 1.6rem;
              font-weight: 800;
              width: 60%;
              color: var(--secondary-green);
              text-transform: uppercase;

              @media screen and (max-width: 680px) {
                width: 100%;
              }
            }

            p {
              font-family: "Montserrat";
              font-size: 1.4rem;
              font-weight: 500;
              color: #767676;
              line-height: 1.5;
              width: 100%;
              margin-top: 0.7rem;

              @media screen and (max-width: 680px) {
                width: 80%;
              }
            }
          }
        }

        .BrainhubCarousel__arrows {
          padding: 15px 21px;
          background-color: var(--primary-green);
        }
      }
    }
  }

  .section-5 {
    position: relative;
    height: auto;
    background: url(${require("../../assets/Fotos/image5.jpg")}) center
      no-repeat;
    background-size: cover;
    margin: 0 auto;

    .content {
      .container {
        display: flex;
        justify-content: space-evenly;
        padding: 28rem 3rem;
        gap: 4rem;

        @media screen and (max-width: 680px) {
          flex-direction: column;
          padding: 28rem 3rem;
        }
      }

      .texts {
        .items {
          p {
            font-family: "Montserrat";
            font-weight: 300;
            font-size: 4rem;
            position: relative;
            z-index: 2;
            color: var(--dark-color-titles);
            width: fit-content;
            @media screen and (max-width: 680px) {
              font-size: 3rem;
            }
          }

          p.description {
            font-size: 1.6rem;
            color: #fff;
            width: 80%;
            top: -3rem;
            position: relative;
            line-height: 1.7;

            @media screen and (max-width: 680px) {
              width: 100%;
            }
          }

          p.handwriting {
            font-family: "Angelic";
            color: #fff;
            font-size: 14rem;
            margin-top: -10rem;
            position: relative;
            z-index: 2;
            left: 3%;
            @media screen and (max-width: 680px) {
              font-size: 10rem;
              margin-top: -7rem;
            }
          }
        }
      }

      .container-form {
        form {
          font-family: "Montserrat";
          font-weight: 300;
          font-size: 1.4rem;
          color: var(--dark-color-titles);

          .item.select {
            label {
              margin-bottom: 1.5rem;
            }

            div > div > div > div {
              height: 5rem;
              display: flex;
              align-items: center;
              border-radius: 3px;

              svg {
                margin: 0;
              }
            }
          }

          .item {
            margin: 2rem 0;
            display: flex;
            flex-direction: column;

            label {
              white-space: nowrap;
            }

            .erro {
              font-size: 14px;
              color: red;
              margin-top: 5px;
            }
          }

          .input {
            span {
              font-size: 14px;
              color: red;
              margin-top: 5px;
            }
          }

          input {
            margin-top: 1.5rem;
            background: #fff;
            outline: none;
            border-bottom: 0.6px solid #fff;
            padding-left: 2rem;
            width: 40rem;
            height: 5rem;
            border-radius: 3px;
            border-style: none;
            border-bottom-style: solid;
            color: var(--dark-color-titles);
            font-family: "Montserrat";

            @media screen and (max-width: 680px) {
              width: 100%;
            }
          }

          /* .ant-select,
          .ant-picker,
          .ant-picker-input {
            width: 40rem;
            height: 5rem;
            margin-top: 1.5rem;

            input {
              margin: 0;
              padding: 0;
              width: unset;
              height: unset;
            }
          }

          .ant-picker {
            padding: 0 2rem;
          }

          .ant-picker-input {
            margin: 0;
          }

          .ant-select-selector {
            height: 5rem;
            padding: 0 2rem;
          }

          .ant-select-selection-item {
            display: flex;
            align-items: center;
          } */

          textarea {
            margin-top: 1.5rem;
            background: #fff;
            outline: none;
            border-bottom: 0.6px solid #fff;
            padding-left: 2rem;
            padding-top: 2rem;
            width: 40rem;
            height: 5rem;
            border-style: none;
            border-bottom-style: solid;
            resize: none;
            color: var(--dark-color-titles);
            font-family: "Montserrat";
          }

          .submit-button {
            .wait {
              button {
                filter: opacity(0.2);
              }
            }

            button {
              background-color: var(--dark-color-titles);
              color: #fff;
              padding: 2rem 4rem;
              border: none;
              cursor: pointer;
              font-weight: 600;
              font-family: "Montserrat";
              font-size: 1.2rem;
              transition: all ease 0.5s;

              :hover {
                background-color: var(--primary-green);
              }
            }
          }
        }
      }
    }
  }
  .section-6 {
    position: relative;
    height: auto;
    padding-bottom: 28rem;
    background: url(${require("../../assets/Fotos/image6.jpg")});
    background-position: center top;
    background-size: cover;
    margin: 0 auto;

    @media screen and (max-width: 680px) {
      padding-bottom: 10rem;
    }

    .content {
      position: relative;
      top: 0;
      width: 100%;

      .top-text {
        padding-top: 27rem;
        text-align: center;
        position: relative;
        @media screen and (max-width: 680px) {
          padding-top: 10rem;
        }
        ::after {
          position: absolute;
          content: "";
          top: 90%;
          transform: translate(0, 12px);
          left: 50%;
          width: 80%;
          transform: translate(-50%, 0);
          height: 0.1rem;
          background: #000;
          opacity: 0.1;
        }
        p:first-child {
          font-family: "Montserrat";
          font-weight: 300;
          font-size: 4rem;
          color: #fff;
          @media screen and (max-width: 680px) {
            font-size: 3rem;
          }
        }
        p:last-child {
          font-family: "Angelic";
          font-size: 12rem;
          margin-top: -8rem;
          color: var(--primary-green);

          @media screen and (max-width: 680px) {
            font-size: 7rem;
            margin-top: -4rem;
          }
        }
      }

      .container-items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        gap: 4rem;
        padding: 0 8rem;
        max-width: 120rem;
        margin: 0 auto;
        @media screen and (max-width: 680px) {
          padding: 0 3rem;
        }

        .box-container {
          background: var(--primary-green);
          padding: 4rem;
          height: auto;
          margin-top: 4rem;
          transition: all ease 450ms;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .social-icons {
            margin-top: 3rem;
            position: absolute;
            top: 38%;
            left: 50%;
            transform: translate(-50%, 0);
            display: flex;

            a:not(:first-child) {
              margin-left: 1rem;
            }

            a {
              font-family: "SocialIcons";
              font-size: 2.6rem;
              color: #000;
            }

            .social-icon {
              width: 27px;
              height: 27px;

              svg {
                fill: #000;
                transition: all ease 0.5s;

                :hover {
                  transform: scale(1.2);
                }
              }
            }
          }

          .icon {
            background: #efefef;
            border-radius: 100%;
            height: 20rem;
            min-height: 20rem;
            width: 20rem;
            min-width: 20rem;
            overflow: hidden;
            img {
              width: 100%;
              max-width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .texts {
            margin-top: 3rem;
            .doctor-info {
              display: flex;

              .black-box {
                width: 3.7rem;
                height: 3.7rem;
                background: #202d2d;
                margin: 0 1.2rem 0 0;
              }

              .text {
                h2 {
                  font-family: "Montserrat";
                  font-size: 1.6rem;
                  font-weight: 800;
                  color: #fff;
                }
                p {
                  font-family: "Montserrat";
                  font-size: 1.4rem;
                  font-weight: 500;
                  color: #fff;
                  line-height: 1.5;
                  margin-top: 0;
                }
              }
            }

            p {
              font-family: "Montserrat";
              font-size: 1.4rem;
              font-weight: 500;
              color: #fff;
              line-height: 1.5;
              margin-top: 0.7rem;
            }
          }
        }
      }
    }
  }
`;

import React, { useState } from "react";
import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming";
import {
  Dropdown,
  Field,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import { Row, Col } from "@zendeskgarden/react-grid";

let especialidade = "";

const SelectComp = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState(data.especialidades[0]);

  especialidade = selectedItem.titulo;

  return (
    <ThemeProvider theme={{ ...DEFAULT_THEME }}>
      <Row justifyContent="center">
        <Col>
          <Dropdown
            selectedItem={selectedItem}
            onSelect={setSelectedItem}
            downshiftProps={{ itemToString: (item) => item && item.titulo }}
          >
            <Field>
              <Select>{selectedItem.titulo}</Select>
            </Field>
            <Menu>
              {data.especialidades.map((option) => (
                <Item key={option.titulo} value={option}>
                  {option.titulo}
                </Item>
              ))}
            </Menu>
          </Dropdown>
        </Col>
      </Row>
    </ThemeProvider>
  );
};

export { SelectComp, especialidade };

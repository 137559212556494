import React, { useState } from "react";
import GoogleMapReact from "google-map-react";

import { Marker } from "./styles";

const MarkerComponent = (props) => {
  return (
    <Marker>
      <img src={require("../../assets/Icones/marker_maps.svg")} alt="" />
    </Marker>
  );
};

const Map = () => {
  const [center] = useState({
    lat: -24.7892273,
    lng: -50.0091751,
  });
  const [zoom] = useState(18);
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyClhfWAUEhKYlTQDeCopqscsy6LqCbsmqk" }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        <MarkerComponent lat={-24.7892273} lng={-50.0091751} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;

import styled from 'styled-components';

export const Noticia = styled.div`
  max-width: 100rem;
  padding: 0 5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';

  @media screen and (max-width: 680px) {
    padding: 0 2rem;
  }

  .block-noticia:first-child {
    margin: 8rem 0 4rem 0;

    @media screen and (max-width: 680px) {
      margin: 0;
    }
  }

  .block-noticia:not(:first-child) {
    margin: 4rem 0;
  }

  .block-noticia {
    width: 70rem;
    height: 100%;
    border-bottom: 1px solid #707070;

    @media screen and (max-width: 680px) {
      width: 100%;
    }
    .top {
      width: 70rem;

      @media screen and (max-width: 680px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 30rem;
        object-fit: cover;
        @media screen and (max-width: 680px) {
          width: 100%;
        }
      }
    }
    .bottom {
      display: flex;
      margin-top: 2rem;
      .black-box {
        width: 20rem;
        height: 3rem;
        background: #202d2d;
        margin: 1.2rem;
      }

      .holder {
        .title {
          margin-top: 1.2rem;
          margin-bottom: 1.2rem;
          h4 {
            font-weight: 800;
            font-size: 1.6rem;
            color: #458e7d;
          }
        }

        .info {
          display: flex;
          margin-bottom: 1.2rem;
          color: #767676;
          font-size: 1.1rem;

          span.bold {
            font-weight: 700;
          }

          span {
            margin-right: 0.5rem;
          }
          div {
            margin-right: 1.2rem;
          }
        }

        .description {
          font-size: 1.2rem;
          color: #767676;
          margin-bottom: 4rem;
          line-height: 1.6;

          p {
            margin-top: 2rem;
          }
        }
      }
    }
  }
`;

export const Container = styled.div`
  padding-top: 15rem;

  > img {
    width: 100%;
  }
`;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "@brainhubeu/react-carousel/lib/style.css";
import ReactHtmlParser from "react-html-parser";

import Header from "../../../components/Header";
import Contato from "../../../components/Contato";
import Footer from "../../../components/Footer";
import Whatsapp from "../../../components/Whatsapp";

import { Container, Noticia } from "./styles";

import api from "../../../services/api";

export default function Home({ match }) {
  const slug = match.params.slug;
  const [dados, setDados] = useState();
  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [categorias, setCategorias] = useState();

  useEffect(() => {
    api.get(`/posts?slug=${slug}`).then((response) => {
      setDados(response.data[0]);
      setDay(response.data[0].date.substring(8, 10));
      const month = response.data[0].date.substring(5, 7);
      setYear(response.data[0].date.substring(0, 4));

      if (month === "01") {
        setMonth("Jan");
      }

      if (month === "02") {
        setMonth("Fev");
      }

      if (month === "03") {
        setMonth("Mar");
      }

      if (month === "04") {
        setMonth("Abr");
      }

      if (month === "05") {
        setMonth("Mai");
      }

      if (month === "06") {
        setMonth("Jun");
      }

      if (month === "07") {
        setMonth("Jul");
      }

      if (month === "08") {
        setMonth("Ago");
      }

      if (month === "09") {
        setMonth("Set");
      }

      if (month === "10") {
        setMonth("Out");
      }

      if (month === "11") {
        setMonth("Nov");
      }

      if (month === "12") {
        setMonth("Dez");
      }
    });

    api.get("/categories").then((response) => {
      setCategorias(response.data);
    });
  }, [slug]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f495cbe39953858";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Whatsapp />
      <Helmet>
        <title>
          {!!dados && dados.title.rendered
            ? `${!!dados && dados.title.rendered}`
            : "RBS | Odontologia & Estética Facial"}
        </title>
        <meta
          name="description"
          content={ReactHtmlParser(!!dados && dados.excerpt.rendered)}
        />
        <meta
          name="keywords"
          content="odontologia, estética, estética facial, rbs, RBS, clareamento, clareamento dentário, ortodontia, prótese dentária, prótese, consulta, "
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "/screenshot.png"}
        />
        <meta name="facebook-domain-verification" content="5vrh0woenh3w5ogxzrmoxrff4lc89n" />
      </Helmet>

      <Header />
      <Container id="container">
        <img src={require("../../../assets/Fotos/banner_blog.jpg")} alt="" />

        <Noticia>
          <div className="block-noticia">
            <div className="top">
              <img
                src={!!dados && dados.acf.imagem_destaque.sizes.large}
                alt=""
              />
            </div>
            <div className="bottom">
              <div className="black-box"></div>
              <div className="holder">
                <div className="title">
                  <h4>{!!dados && dados.title.rendered}</h4>
                </div>
                <div className="info">
                  <div className="data-post">
                    <p>
                      {month}, {day}, {year}
                    </p>
                  </div>
                  <div className="categories">
                    <span>Em</span>
                    {!!dados &&
                      !!dados.categories &&
                      dados.categories.map(
                        (cat, index) =>
                          !!categorias &&
                          categorias.map((cat_nome) => {
                            if (cat === cat_nome.id) {
                              return (
                                <span key={index} className="bold">
                                  {cat_nome.name}
                                </span>
                              );
                            }
                            return null;
                          })
                      )}
                  </div>
                </div>
                <div className="description">
                  {ReactHtmlParser(!!dados && dados.content.rendered)}
                </div>
              </div>
            </div>
          </div>
        </Noticia>
        <Contato />
        <Footer />
      </Container>
    </>
  );
}

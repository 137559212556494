import styled from "styled-components";

export const Container = styled.section`
  position: relative;
  height: auto;

  .content {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 0 auto;

    @media screen and (max-width: 680px) {
      display: initial;
      grid-template-columns: initial;
    }

    .contato {
      height: 120rem;
      background: url(${require("../../assets/Fotos/bg-contato.jpg")}) no-repeat;
      background-position: center top;
      background-size: cover;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* padding: 8rem; */

      @media screen and (max-width: 680px) {
        align-items: initial;

        height: auto;
        padding: 28rem 3rem;
      }

      .top-text {
        position: relative;

        @media screen and (max-width: 680px) {
          margin-top: 0;
        }

        p:first-child {
          font-family: "Montserrat";
          font-weight: 300;
          font-size: 4rem;
          line-height: 1;
          color: #fff;
          @media screen and (max-width: 680px) {
            font-size: 2rem;
          }
        }
        p:last-child {
          font-family: "Angelic";
          font-size: 8rem;
          line-height: 0.3;
          /* margin-top: -8rem; */
          color: var(--dark-color-titles);
        }
      }
      form {
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 1.4rem;
        color: #fff;
        margin-top: 5rem;

        .item {
          margin: 2rem 0;
          display: flex;
          flex-direction: column;

          label {
            white-space: nowrap;
          }

          span {
            font-size: 14px;
            color: red;
            margin-top: 5px;
          }
        }

        input {
          background: transparent;
          outline: none;
          border-bottom: 0.6px solid #fff;
          padding-left: 2rem;
          width: 30rem;
          height: 3.5rem;
          border-style: none;
          border-bottom-style: solid;
          color: #fff;
          font-family: "Montserrat";
          @media screen and (max-width: 680px) {
            width: 100%;
          }
        }

        textarea {
          background: transparent;
          outline: none;
          border-bottom: 0.6px solid #fff;
          padding-left: 2rem;
          padding-top: 2rem;
          width: 30rem;
          height: 5rem;
          border-style: none;
          border-bottom-style: solid;
          resize: none;
          color: #fff;
          font-family: "Montserrat";
          @media screen and (max-width: 680px) {
            width: 100%;
          }
        }

        .submit-button {
          .wait {
            button {
              filter: opacity(0.2);
            }
          }
          button {
            background: var(--dark-color-titles);
            color: #fff;
            padding: 2rem 4rem;
            border: none;
            cursor: pointer;
            font-weight: 600;
            font-family: "Montserrat";
            font-size: 1.2rem;
            transition: all ease 0.5s;

            :hover {
              background: #fff;
              color: var(--primary-green);
            }
          }
        }
      }
    }

    .map {
      @media screen and (max-width: 680px) {
        height: 100rem;
      }
    }
  }
`;

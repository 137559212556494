import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "@brainhubeu/react-carousel/lib/style.css";
import ReactHtmlParser from "react-html-parser";

import Header from "../../components/Header";
import Contato from "../../components/Contato";
import Footer from "../../components/Footer";
import Whatsapp from "../../components/Whatsapp";

import { Container, Noticias } from "./styles";

import api from "../../services/api";

export default function Home() {
  const [posts, setPosts] = useState();
  const [dados, setDados] = useState();
  const [categorias, setCategorias] = useState();

  useEffect(() => {
    api.get("/posts?per_page=99").then((response) => {
      setPosts(response.data);
    });

    api.get("/pages?slug=home&_fields=acf.quem_somos").then((response) => {
      setDados(response.data[0].acf.quem_somos);
    });

    api.get("/categories").then((response) => {
      setCategorias(response.data);
    });
  }, []);

  console.log(posts);

  return (
    <>
      <Whatsapp />
      <Helmet>
        <title>RBS | Odontologia & Estética Facial</title>
        <meta name="description" content={ReactHtmlParser(!!dados && dados)} />
        <meta
          name="keywords"
          content="odontologia, estética, estética facial, rbs, RBS, clareamento, clareamento dentário, ortodontia, prótese dentária, prótese, consulta, "
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "/screenshot.png"}
        />
        <meta name="facebook-domain-verification" content="5vrh0woenh3w5ogxzrmoxrff4lc89n" />
      </Helmet>

      <Header />
      <Container id="container">
        <img src={require("../../assets/Fotos/banner_blog.jpg")} alt="" />

        <Noticias>
          {!!posts &&
            posts.map((post, index) => {
              const day = post.date.substring(8, 10);
              const month = post.date.substring(5, 7);
              const year = post.date.substring(0, 4);
              let mes = "";

              if (month === "01") {
                mes = "Jan";
              }

              if (month === "02") {
                mes = "Fev";
              }

              if (month === "03") {
                mes = "Mar";
              }

              if (month === "04") {
                mes = "Abr";
              }

              if (month === "05") {
                mes = "Mai";
              }

              if (month === "06") {
                mes = "Jun";
              }

              if (month === "07") {
                mes = "Jul";
              }

              if (month === "08") {
                mes = "Ago";
              }

              if (month === "09") {
                mes = "Set";
              }

              if (month === "10") {
                mes = "Out";
              }

              if (month === "11") {
                mes = "Nov";
              }

              if (month === "12") {
                mes = "Dez";
              }

              return (
                <a
                  href={`/blog/${post.slug}`}
                  className="block-noticia"
                  key={index}
                >
                  <div className="top">
                    <img src={post.acf.imagem_destaque.sizes.medium} alt="" />
                  </div>
                  <div className="bottom">
                    <div className="black-box"></div>
                    <div className="holder">
                      <div className="title">
                        <h4>{post.title.rendered}</h4>
                      </div>
                      <div className="info">
                        <div className="data-post">
                          <p>
                            {mes}, {day}, {year}
                          </p>
                        </div>
                        <div className="categories">
                          <span>Em</span>
                          {!!post &&
                            post.categories.map(
                              (cat) =>
                                !!categorias &&
                                categorias.map((cat_nome) => {
                                  if (cat === cat_nome.id) {
                                    return (
                                      <span className="bold">
                                        {cat_nome.name}
                                      </span>
                                    );
                                  }
                                  return null;
                                })
                            )}
                        </div>
                      </div>
                      <div className="description">
                        {ReactHtmlParser(post.excerpt.rendered)}
                      </div>
                      <div className="button">
                        <p>Continuar lendo</p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
        </Noticias>
        <Contato />
        <Footer />
      </Container>
    </>
  );
}

import { createGlobalStyle } from "styled-components";
// import "antd/es/notification/style/css";
// import "antd/es/select/style/css";
// import "antd/es/date-picker/style/css";

export default createGlobalStyle`

@font-face {
  font-family: SocialIcons;
  src: url(${require("../assets/Fonts/social_media_circled.otf")});
}


@font-face {
  font-family: Angelic;
  src: url(${require("../assets/Fonts/angelic_bonques_script.otf")});
}

  :root{
    font-size: 10px;
    --primary-green: #56B9AB;
    --dark-color:#3B3B3B;
    --secondary-green: #458E7D;
    --dark-color-titles: #202D2D;
    
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    scroll-behavior: smooth;
    /* border: 1px solid red; */
  }

  #active_header {
    margin-top: 0;
  }

  #inactive_header{
    margin-top: -150rem;
  }

  #wpp{
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 99;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }

  .hamburger-react{
    z-index: 999;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: #fff;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  .container{
    max-width: 128rem;
    padding: 0 2rem;
    margin: 0 auto;
  }

p {
  margin: 0;
}

`;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ReactHtmlParser from "react-html-parser";
import { SiFacebook } from "react-icons/si";
import { FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

import { useFormik } from "formik";
import * as Yup from "yup";
import ademail from "../../services/ademail";

import Header from "../../components/Header";
import Contato from "../../components/Contato";
import Footer from "../../components/Footer";
import { DatePickerComp, formatedDate } from "../../components/DatePicker";
import { SelectComp, especialidade } from "../../components/SelectComp";
import Whatsapp from "../../components/Whatsapp";
// Não é fácil ser programador :/

import { Container } from "./styles";

import api from "../../services/api";

export default function Home() {
  const [dados, setDados] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const notify = () =>
    toast.error("Ocorreu uma falha.", {
      style: {
        border: "1px solid #f7312a",
        boxShadow: "10px 10px 75px -13px rgba(247,49,42,1)",
        fontFamily: "Montserrat",
      },
    });
  const notifySuccess = () =>
    toast.success("Enviado com sucesso!", {
      style: {
        border: "1px solid #28fc76",
        boxShadow: "10px 10px 75px -13px rgba(40,252,118,1)",
        fontFamily: "Montserrat",
      },
    });

  useEffect(() => {
    api.get("/pages?slug=home&_fields=acf").then((response) => {
      setDados(response.data[0].acf);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Informe um e-mail válido")
        .required("*Campo e-mail é obrigatório"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                                  <p>E-mail: ${values.email}</p>
                                  <p>Especialista: ${especialidade}</p>
                                  <p>Data: ${formatedDate}</p>
                                `;

      const mail = {
        to: "contato@rbs.com.br",
        from: values.email,
        as: values.email,
        bcc: JSON.stringify(["web@agenciaade.com.br"]),
        subject: "Novo contato via site - RBS Odontologia e Estética Facial",
        message: body,
      };

      try {
        // Sucesso ao enviar
        setBtnLoader(true);
        await ademail.post("/email", mail);
        notifySuccess();
        resetForm();
        setBtnLoader(false);
      } catch (error) {
        notify();
        setBtnLoader(false);
      }
    },
  });

  return (
    <>
      <Toaster position="bottom-right" />
      <Whatsapp />
      <Helmet>
        <title>RBS | Odontologia & Estética Facial</title>
        <meta
          name="description"
          content={ReactHtmlParser(!!dados && dados.quem_somos)}
        />
        <meta
          name="keywords"
          content="odontologia, estética, estética facial, rbs, RBS, clareamento, clareamento dentário, ortodontia, prótese dentária, prótese, consulta, "
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "/screenshot.png"}
        />
        <meta name="facebook-domain-verification" content="5vrh0woenh3w5ogxzrmoxrff4lc89n" />
      </Helmet>
      <Header />
      <Container id="container">
        <section className="section-1">
          <Carousel dots autoPlay={3000} animationSpeed={1000} infinite>
            {!!dados &&
              dados.banners.map((banner, index) => (
                <div className="container-slide-1" key={index}>
                  <div className="items">
                    <p>{banner.titulo}</p>
                    <p className="handwriting">{banner.subtitulo}</p>
                    <div className="green-box"></div>
                    <p className="description">{banner.descricao}</p>
                  </div>
                  <img src={banner.imagem.sizes.large} alt="" />
                </div>
              ))}
          </Carousel>
        </section>
        <section className="section-2" id="sobre">
          <div className="content">
            <div className="container">
              <div className="top-text">
                <p>Quem</p>
                <p>somos?</p>
              </div>
              <div className="bottom-text">
                {ReactHtmlParser(!!dados && dados.quem_somos)}
                <div className="counter-numbers-main">
                  {!!dados &&
                    dados.numeros.map((numero, index) => {
                      if (index % 2 === 0) {
                        return (
                          <div className="counter-numbers" key={index}>
                            <CountUp
                              prefix={"+de "}
                              end={numero.numero}
                              redraw={true}
                            >
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <div>
                                    <span ref={countUpRef} />
                                    <p>{numero.descricao}</p>
                                  </div>
                                </VisibilitySensor>
                              )}
                            </CountUp>
                          </div>
                        );
                      } else if (index === 3) {
                        return (
                          <div className="counter-numbers" key={index}>
                            <CountUp
                              suffix={"%"}
                              end={numero.numero}
                              redraw={true}
                            >
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <div>
                                    <span ref={countUpRef} />
                                    <p>{numero.descricao}</p>
                                  </div>
                                </VisibilitySensor>
                              )}
                            </CountUp>
                          </div>
                        );
                      } else
                        return (
                          <div className="counter-numbers" key={index}>
                            <CountUp end={numero.numero} redraw={true}>
                              {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start} delayedCall>
                                  <div>
                                    <span ref={countUpRef} />
                                    <p>{numero.descricao}</p>
                                  </div>
                                </VisibilitySensor>
                              )}
                            </CountUp>
                          </div>
                        );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-3">
          <div className="content">
            <div className="items">
              <p>Excelência em</p>
              <p className="handwriting">Odontologia</p>
              <div className="aspas">
                <img src={require("../../assets/Icones/aspa.svg")} alt="" />
              </div>
              <p className="description">
                {!!dados && dados.depoimento.descricao}
              </p>
              <p className="unknownAuthor">
                {!!dados && dados.depoimento.autor}
              </p>
            </div>
          </div>
        </section>
        <section className="section-4" id="especialidades">
          <div className="content">
            <div className="top-text">
              <p>Nossas</p>
              <p>especialidades</p>
            </div>
            <Carousel
              arrows
              infinite
              itemWidth={350}
              animationSpeed={250}
              slidesPerPage={3}
              offset={50}
              breakpoints={{
                640: {
                  slidesPerPage: 1,
                  arrows: false,
                  itemsWidth: "100%",
                },
                900: {
                  slidesPerPage: 2,
                  arrows: false,
                },
              }}
            >
              {!!dados &&
                dados.especialidades.map((especialidade, index) => (
                  <div className="box-container" key={index}>
                    <div className="icon">
                      <img src={especialidade.icone.sizes.thumbnail} alt="" />
                    </div>
                    <div className="texts">
                      <h2>{especialidade.titulo}</h2>
                      <p>{especialidade.descricao}</p>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </section>
        <section className="section-5">
          <div className="content">
            <div className="container">
              <div className="texts">
                <div className="items">
                  <p>Agende sua</p>
                  <p className="handwriting">consulta</p>
                  <p className="description">
                    Preencha com as informações no quadro ao lado, selecione o
                    especialista e uma data disponivel para sua consulta.
                  </p>
                </div>
              </div>
              <div className="container-form">
                <form onSubmit={formik.handleSubmit}>
                  <div className="item input">
                    <label htmlFor="">Seu endereço de email*</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span>{formik.errors.email}</span>
                    ) : null}
                  </div>
                  <div className="item select">
                    <label htmlFor="">Selecione a areá de interesse*</label>
                    {dados && <SelectComp data={dados} />}
                  </div>

                  <div className="item">
                    <label htmlFor="">Selecione a data*</label>
                    <DatePickerComp />
                  </div>

                  <div className="submit-button">
                    {btnLoader ? (
                      <button disabled className="wait" type="submit">
                        Carregando
                      </button>
                    ) : (
                      <button type="submit">Agendar Consulta</button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="section-6">
          <div className="content">
            <div className="top-text">
              <p>Nossa equipe de</p>
              <p>especialistas</p>
            </div>

            <div className="container-items">
              {!!dados &&
                dados.equipe.map((pessoa, index) => (
                  <div className="box-container" key={index}>
                    <div className="social-icons">
                      {!!pessoa.redes_sociais &&
                        pessoa.redes_sociais.map((rede) => {
                          if (rede.rede_social === "Facebook") {
                            return (
                              <a href={rede.link}>
                                <div className="social-icon">
                                  <SiFacebook />
                                </div>
                              </a>
                            );
                          }
                          if (rede.rede_social === "Instagram") {
                            return (
                              <a href={rede.link}>
                                <div className="social-icon">
                                  <FaInstagramSquare />
                                </div>
                              </a>
                            );
                          }
                          if (rede.rede_social === "Linkedin") {
                            return (
                              <a href={rede.link}>
                                <div className="social-icon">
                                  <FaLinkedin />
                                </div>
                              </a>
                            );
                          }
                          return null;
                        })}
                    </div>
                    <div className="icon">
                      <img src={pessoa.foto.sizes.thumbnail} alt="" />
                    </div>
                    <div className="texts">
                      <div className="doctor-info">
                        <div className="black-box"></div>
                        <div className="text">
                          <h2>{pessoa.nome}</h2>
                          <p>{pessoa.cargo}</p>
                        </div>
                      </div>
                      <p>{pessoa.descricao}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
        <Contato />
        <Footer />
      </Container>
    </>
  );
}

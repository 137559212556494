import styled from "styled-components";

export const Signature = styled.div`
  background: #fff;
  height: 10rem;
  @media screen and (max-width: 680px) {
    height: auto;
    padding: 3rem;
  }

  .content {
    max-width: 100rem;
    margin: 0 auto;
  }

  .bottom-content {
    display: flex;
    padding: 5rem 3rem;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 680px) {
      padding: 0;
      flex-direction: column;
    }

    p:nth-child(2) {
      @media screen and (max-width: 680px) {
        margin-top: 2rem;
      }
    }

    p {
      font-size: 1.2rem;
      font-family: "Montserrat";
      font-weight: 500;
      color: #767676;

      a {
        color: #767676;

        :hover {
          color: #1a1a1a;
        }
      }
    }
  }
`;

export const Container = styled.footer`
  background: var(--dark-color-titles);

  .content {
    margin: 0 auto;
    max-width: 100rem;
    .top-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5rem 3rem;

      @media screen and (max-width: 680px) {
        flex-direction: column;
      }

      .logo {
        margin-right: 8rem;
        @media screen and (max-width: 680px) {
          margin-right: 0;
          margin-bottom: 6rem;
        }
        img {
          width: 10rem;
        }
      }

      .block-info.socials {
        display: flex;

        @media screen and (max-width: 680px) {
          margin: 5rem 0;
        }

        svg {
          width: 2.5rem;
          height: auto;
          fill: var(--primary-green);
          margin: 0 5px;
        }
      }

      .mail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        :hover {
          p {
            color: var(--primary-green);
          }
        }

        img {
          width: 4rem;
          margin: 1rem 0;
        }

        h3 {
          font-family: "Montserrat";
          font-size: 1.6rem;
          font-weight: 700;
          margin: 1rem 0;
          color: #fff;
        }

        p {
          font-size: 1.2rem;
          font-family: "Montserrat";
          font-weight: 500;
          color: #fff;
          transition: all ease 0.5s;

          span {
            font-family: "Montserrat";
          }
        }
      }

      .address {
        width: 17%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 680px) {
          width: 50%;
        }

        :hover {
          p {
            color: var(--primary-green);
          }
        }

        img {
          width: 4rem;
          margin: 1rem 0;
        }

        h3 {
          font-family: "Montserrat";
          font-size: 1.6rem;
          font-weight: 700;
          margin: 1rem 0;
          color: #fff;
        }

        p {
          font-size: 1.2rem;
          font-family: "Montserrat";
          font-weight: 500;
          color: #fff;
          transition: all ease 0.5s;

          span {
            font-family: "Montserrat";
          }
        }
      }

      .block-info {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 680px) {
          margin-bottom: 3rem;
        }

        img {
          width: 4rem;
          margin: 1rem 0;
        }

        h3 {
          font-family: "Montserrat";
          font-size: 1.6rem;
          font-weight: 700;
          margin: 1rem 0;
          color: #fff;
        }

        a {
          font-size: 1.2rem;
          font-family: "Montserrat";
          font-weight: 500;
          color: #fff;
          transition: all ease 0.5s;

          :hover {
            color: var(--primary-green);
          }

          span {
            font-family: "Montserrat";
            font-weight: 300;
          }
        }
      }
    }
  }
`;

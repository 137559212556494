import React from "react";
import { SiFacebook } from "react-icons/si";
import { FaInstagramSquare } from "react-icons/fa";

import { Container, Signature } from "./styles";

export default function Footer() {
  return (
    <Container>
      <div className="content">
        <div className="top-content">
          <div className="logo">
            <img
              src={require("../../assets/Icones/logo_white.svg")}
              alt="Phone"
            />
          </div>
          <div className="block-info">
            <img src={require("../../assets/Icones/phone.svg")} alt="Phone" />
            <h3>Telefone</h3>
            <a href="tel: +5542999021449">
              <span>42</span> 9 9902.1449
            </a>
            <a href="tel: +55423232-0100">
              <span>42</span> 3232-0100
            </a>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/maps/place/RBS+Implantes/@-24.7892273,-50.0091751,15z/data=!4m2!3m1!1s0x0:0x1a3b48267c3e63f6?sa=X&ved=2ahUKEwj9j-q_p5TvAhUqHrkGHdyxCjgQ_BIwCnoECBcQBQ"
            className="address"
          >
            <img src={require("../../assets/Icones/pin.svg")} alt="Phone" />
            <h3>Endereço</h3>
            <p>
              <span>Rua</span> Benjamin Constant, 169 - Centro, Castro - PR,
              84165-220
            </p>
          </a>
          <a className="mail" href="mailto: contato@rbs.com.br">
            <img src={require("../../assets/Icones/message.svg")} alt="Phone" />
            <h3>Email</h3>
            <p>
              <span>contato@</span>rbs.com.br
            </p>
          </a>
          <div className="block-info socials">
            <div className="socials-icons">
              <FaInstagramSquare />
              <SiFacebook />
            </div>
            <h3>Redes Sociais</h3>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/rbsodontocastro/"
            >
              <span>@</span>rbsodontocastro
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/rbsodonto/?rf=196545353778628"
            >
              /rbsodonto
            </a>
          </div>
        </div>
      </div>

      <Signature>
        <div className="content">
          <div className="bottom-content">
            <p>
              2020 - RBS - Odontologia e Estética Facial - Todos os Direitos
              Reservados
            </p>
            <p>
              Criado com{" "}
              <img
                style={{ width: "2rem" }}
                src={require("../../assets/Icones/heart.svg")}
                alt=""
              />{" "}
              por <a href="https://agenciaade.com.br/">agenciaade.com.br</a>
            </p>
          </div>
        </div>
      </Signature>
    </Container>
  );
}

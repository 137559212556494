import styled from "styled-components";

export const HeaderContainerMobile = styled.header`
  padding: 1rem 0 1rem 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  transition: all ease 600ms;
  background: #fff;

  .container.short {
    .top-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ::after {
        display: none;
      }

      img {
        width: 6rem;
      }

      @keyframes fadeMenuIn {
        from {
          filter: opacity(0);
        }
        to {
          filter: opacity(1);
        }
      }

      @keyframes fadeMenuOut {
        0% {
          filter: opacity(1);
        }
        100% {
          filter: opacity(0);
        }
      }

      nav.inactive {
        animation: fadeMenuOut 900ms ease;
      }

      nav.active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeMenuIn 700ms ease-in-out;
      }

      nav {
        display: none;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.87);

        ul {
          display: flex;
          flex-direction: column;
          margin: 11% 8%;
          text-align: center;

          li {
            position: relative;
            padding: 0.5rem 1rem;
            overflow: hidden;
            margin-bottom: 2rem;

            a {
              font-family: "Montserrat", sans-serif;
              color: var(--dark-color);
              font-weight: 500;
              z-index: 2;
              position: relative;
              cursor: pointer;
              font-size: 1.6rem;
              transition: all ease 600ms;
            }
          }
        }
      }

      .social-icons {
        a:not(:first-child) {
          margin-left: 1rem;
        }

        a {
          font-family: "SocialIcons";
          font-size: 2.6rem;
          color: var(--primary-green);
          transition: all ease 0.5s;

          :hover {
            color: #000;
          }
        }
      }

      .container-info-icons {
        font-family: "Montserrat", sans-serif;
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        img {
          width: 4.5rem;
        }

        .info-icons {
          display: flex;
          align-items: center;

          margin-bottom: 2rem;

          .text-info {
            margin-left: 2rem;
            p.bold {
              font-weight: 700;
            }
            p {
              font-size: 1.4rem;
              margin: 0;
            }
            a {
              color: #000;
              transition: all ease 0.5s;

              :hover {
                color: var(--primary-green);
              }
            }
          }
        }
      }
    }
  }
`;

export const HeaderContainerShort = styled.header`
  padding: 2rem 0 2rem 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  transition: all ease 600ms;
  background: #fff;
  margin-top: -150rem;

  .container.short {
    .top-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      ::after {
        display: none;
      }

      img {
        width: 9rem;
      }

      nav {
        ul {
          display: flex;
          li:not(:first-child) {
            margin-left: 1rem;
          }

          li {
            position: relative;
            padding: 0.5rem 1rem;
            overflow: hidden;
            ::before {
              content: "";
              position: absolute;
              z-index: 1;
              left: -150%;
              top: 0;
              width: 100%;
              height: 100%;
              background: var(--primary-green);
              transition: all ease 500ms;
            }
            :hover {
              ::before {
                left: 0;
              }
              a {
                color: #fff;
              }
            }
            a {
              font-family: "Montserrat", sans-serif;
              color: var(--dark-color);
              font-weight: 500;
              z-index: 2;
              position: relative;
              cursor: pointer;
              font-size: 1.4rem;
              transition: all ease 600ms;
            }
          }
        }
      }

      .social-icons {
        a:not(:first-child) {
          margin-left: 1rem;
        }

        a {
          font-family: "SocialIcons";
          font-size: 2.6rem;
          color: var(--primary-green);
          transition: all ease 0.5s;

          :hover {
            color: #000;
          }
        }
      }

      .container-info-icons {
        font-family: "Montserrat", sans-serif;
        display: flex;
        img {
          width: 4.5rem;
        }

        .info-icons {
          display: flex;
          align-items: center;
          margin-left: 2rem;

          .text-info {
            margin-left: 2rem;
            p.bold {
              font-weight: 700;
            }
            p {
              font-size: 1.4rem;
              margin: 0;
            }

            a {
              color: #000;

              transition: all ease 0.5s;

              :hover {
                color: var(--primary-green);
              }
            }
          }
        }
      }
    }
  }
`;

export const HeaderContainer = styled.header`
  padding: 2rem 0 2rem 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
  background: #fff;
  transition: all ease 600ms;

  .container {
    transition: all ease 550ms;
    .top-header {
      display: flex;
      justify-content: space-between;
      position: relative;
      ::after {
        position: absolute;
        content: "";
        top: 100%;
        transform: translate(0, 12px);
        left: 0;
        width: 100%;
        height: 0.1rem;
        background: #e2e2e2;
        opacity: 1;
      }

      img {
        width: 9rem;
      }

      .container-info-icons {
        font-family: "Montserrat", sans-serif;
        display: flex;
        img {
          width: 4.5rem;
        }

        .info-icons {
          display: flex;
          align-items: center;
          margin-left: 2rem;

          .text-info {
            margin-left: 2rem;
            p.bold {
              font-weight: 700;
            }
            p {
              font-size: 1.4rem;
              margin: 0;
            }
            a {
              color: #000;
              transition: all ease 0.5s;

              :hover {
                color: var(--primary-green);
              }
            }
          }
        }
      }
    }

    .bottom-header {
      display: flex;
      justify-content: space-between;
      nav {
        margin-top: 3rem;
        ul {
          display: flex;
          li:not(:first-child) {
            margin-left: 5rem;
          }

          li {
            position: relative;
            padding: 0.5rem 1rem;
            overflow: hidden;
            ::before {
              content: "";
              position: absolute;
              z-index: 1;
              left: -150%;
              top: 0;
              width: 100%;
              height: 100%;
              background: var(--primary-green);
              transition: all ease 500ms;
            }
            :hover {
              ::before {
                left: 0;
              }
              a {
                color: #fff;
              }
            }
            a {
              font-family: "Montserrat", sans-serif;
              color: var(--dark-color);
              font-weight: 500;
              cursor: pointer;
              z-index: 2;
              position: relative;
              font-size: 1.4rem;
              transition: all ease 600ms;
            }
          }
        }
      }

      .social-icons {
        margin-top: 3rem;

        a:not(:first-child) {
          margin-left: 1rem;
        }

        a {
          font-family: "SocialIcons";
          font-size: 2.6rem;
          color: var(--primary-green);
          transition: all ease 0.5s;

          :hover {
            color: #000;
          }
        }
      }
    }
  }
`;

import React from "react";
import ReactWhatsapp from "react-whatsapp";

export default function Whatsapp() {
  return (
    <ReactWhatsapp
      id="wpp"
      number="5542999021449"
      message="Olá, gostaria de conversar."
    >
      <img
        style={{ width: "50px", height: "50px" }}
        src="https://logodownload.org/wp-content/uploads/2015/04/whatsapp-logo-1.png"
        alt="wpp"
      />
    </ReactWhatsapp>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import {
  HeaderContainer,
  HeaderContainerShort,
  HeaderContainerMobile,
} from "./styles";
import { isMobile } from "react-device-detect";
import { Squeeze as Hamburger } from "hamburger-react";

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      const top = window.pageYOffset;

      if (top > 140) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  });

  if (isMobile) {
    return (
      <HeaderContainerMobile>
        <div className="container short">
          <div className="top-header">
            <a href="/">
              <img src={require("../../assets/Icones/logo.svg")} alt="" />
            </a>

            <Hamburger size={20} toggled={isOpen} toggle={setOpen} />
            <nav
              className={`${(isOpen && `active`) || (!isOpen && `inactive`)}`}
            >
              <ul>
                <li>
                  <a href="/">Início</a>
                </li>
                <li>
                  <a href="/#sobre">Sobre</a>
                </li>
                <li>
                  <a href="/#especialidades">Especialidades</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="contato"
                    spy={true}
                    offset={0}
                    duration={2000}
                  >
                    Contato
                  </Link>
                </li>
              </ul>
              <div className="container-info-icons">
                <div className="info-icons">
                  <img
                    src={require("../../assets/Icones/wall-clock.svg")}
                    alt=""
                  />
                  <div className="text-info">
                    <p className="bold">Segunda - Sexta</p>
                    <p>9h - 17h</p>
                  </div>
                </div>
                <div className="info-icons">
                  <img
                    src={require("../../assets/Icones/smartphone.svg")}
                    alt=""
                  />
                  <div className="text-info">
                    <a href="tel: +5542999021449 ">
                      <p className="bold">42 9 9902.1449</p>
                      <p>Telefone</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="social-icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/rbsodonto/?rf=196545353778628"
                >
                  E
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=5542999021449"
                >
                  L
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/rbsodontocastro/"
                >
                  Q
                </a>
                {/* <a href="/">C</a> */}
              </div>
            </nav>
          </div>
        </div>
      </HeaderContainerMobile>
    );
  } else {
    return (
      <>
        <HeaderContainerShort id={`${scrolled && `active_header`}`}>
          <div className={`container short ${scrolled && `active`}`}>
            <div className="top-header">
              <a href="/">
                <img src={require("../../assets/Icones/logo.svg")} alt="" />
              </a>

              <nav>
                <ul>
                  <li>
                    <a href="/">Inicio</a>
                  </li>
                  <li>
                    <a href="/#sobre">Sobre</a>
                  </li>
                  <li>
                    <a href="/#especialidades">Especialidades</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="contato"
                      spy={true}
                      offset={0}
                      duration={2000}
                    >
                      Contato
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="social-icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/rbsodonto/?rf=196545353778628"
                >
                  E
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=5542999021449"
                >
                  L
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/rbsodontocastro/"
                >
                  Q
                </a>
                {/* <a href="/">C</a> */}
              </div>

              <div className="container-info-icons">
                <div className="info-icons">
                  <img
                    src={require("../../assets/Icones/wall-clock.svg")}
                    alt=""
                  />
                  <div className="text-info">
                    <p className="bold">Segunda - Sexta</p>
                    <p>9h - 17h</p>
                  </div>
                </div>
                <div className="info-icons">
                  <img
                    src={require("../../assets/Icones/smartphone.svg")}
                    alt=""
                  />
                  <div className="text-info">
                    <a href="tel: +5542999021449 ">
                      <p className="bold">42 9 9902.1449</p>
                      <p>Telefone</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderContainerShort>

        <HeaderContainer id={`${scrolled && `inactive_header`}`}>
          <div className={`container ${scrolled && `hidden`}`}>
            <div className="top-header">
              <a href="/">
                <img src={require("../../assets/Icones/logo.svg")} alt="" />
              </a>
              <div className="container-info-icons">
                <div className="info-icons">
                  <img
                    src={require("../../assets/Icones/wall-clock.svg")}
                    alt=""
                  />
                  <div className="text-info">
                    <p className="bold">Segunda - Sexta</p>
                    <p>9h - 17h</p>
                  </div>
                </div>
                <div className="info-icons">
                  <img
                    src={require("../../assets/Icones/smartphone.svg")}
                    alt=""
                  />
                  <div className="text-info">
                    <a href="tel: +5542999021449 ">
                      <p className="bold">42 9 9902.1449</p>
                      <p>Telefone</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-header">
              <nav>
                <ul>
                  <li>
                    <a href="/">Inicio</a>
                  </li>
                  <li>
                    <a href="/#sobre">Sobre</a>
                  </li>
                  <li>
                    <a href="/#especialidades">Especialidades</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="contato"
                      spy={true}
                      offset={0}
                      duration={2000}
                    >
                      Contato
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="social-icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/rbsodonto/?rf=196545353778628"
                >
                  E
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=5542999021449"
                >
                  L
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/rbsodontocastro/"
                >
                  Q
                </a>
              </div>
            </div>
          </div>
        </HeaderContainer>
      </>
    );
  }
}

import React, { useLayoutEffect } from "react";
import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Input } from "@zendeskgarden/react-forms";
import { Row, Col } from "@zendeskgarden/react-grid";

import useDate from "../hooks/useDate";

let formatedDate = "";

const DatePickerComp = () => {
  const [date, setState] = useDate();

  useLayoutEffect(() => {
    const element = document.getElementById("dateInput").getAttribute("value");
    formatedDate = element;
  }, [date]);

  return (
    <ThemeProvider theme={{ ...DEFAULT_THEME }}>
      <Row justifyContent="center">
        <Col>
          <Datepicker value={date} onChange={setState} locale="pt">
            <Input id="dateInput" />
          </Datepicker>
        </Col>
      </Row>
    </ThemeProvider>
  );
};

export { DatePickerComp, formatedDate };
